import * as React from "react";
import Box from "@mui/material/Box";
import { Stack, Typography } from "@mui/material";
import OwnershipTable from "../components/ownerships/components/tables/OwnershipTable";

export default function OwnershipPage() {
  return (
    <Box>
      <Stack
        direction="row"
        sx={{ width: "100%", marginBottom: 2, alignItems: "center" }}
      >
        <Typography variant="h5" sx={{ flexGrow: 1 }}>
          Ownerships
        </Typography>
      </Stack>
      <OwnershipTable />
    </Box>
  );
}
